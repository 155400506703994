//***********************************************************
//                  START OF STATIC CODE
//***********************************************************
const shouldBeAnObserver = obj => _.isObject(obj) && !_.isArray(obj);
const clone = obj => JSON.parse(JSON.stringify(obj));

class WidgetServices {
  constructor(widgetProps) {
    this.onPropsChangedRegistrar = this.createEventRegistrar();
    this.widgetProperties = widgetProps;
  }

  pushObservedObject(
    targetObject,
    objectToWatch,
    initialProps,
    onPropsChangedRegistrar
  ) {
    const observersCache = {};

    function definePropertiesOnTarget(target, objectRoot, props, path = []) {
      props =
        props ||
        Object.getOwnPropertyNames(objectRoot).filter(
          prop => !_.isFunction(objectRoot[prop])
        );

      for (const propName of props) {
        defineProperty(target, propName, path);
      }

      return target;
    }

    function getObserver(obj, path) {
      const propPathName = path.join('/');
      if (observersCache[propPathName]) {
        return observersCache[propPathName];
      }

      const newObserver = definePropertiesOnTarget({}, obj, null, path);

      observersCache[propPathName] = newObserver;

      return newObserver;
    }

    function defineProperty(target, propName, path) {
      Object.defineProperty(target, propName, {
        configurable: false,
        enumerable: true,
        get() {
          const propPath = path.concat(propName);
          let propValue = _.get(objectToWatch, propPath);

          if (shouldBeAnObserver(propValue)) {
            propValue = getObserver(propValue, propPath);
          }

          return propValue;
        },
        set(newValue) {
          const propPath = path.concat(propName);
          const propPathName = propPath.join('/');

          const oldPropsClone = clone(objectToWatch);

          _.set(objectToWatch, propPath, newValue);
          delete observersCache[propPathName];

          onPropsChangedRegistrar.fire(oldPropsClone, objectToWatch);
        },
      });
    }

    return definePropertiesOnTarget(targetObject, objectToWatch, initialProps);
  }

  createEventRegistrar() {
    const callbacksArray = [];

    return {
      register(callback) {
        callbacksArray.push(callback);
      },
      fire(...args) {
        callbacksArray.forEach(cb => cb(...args));
      },
    };
  }

  get$widget($w) {
    const $widget = {
      props: this.widgetProperties,
    };

    $widget.onPropsChanged = cb => {
      this.onPropsChangedRegistrar.register(cb);
    };

    $widget.fireEvent = (eventName, data) => {
      $w.fireEvent(eventName, $w.createEvent('widgetEvent', { data }));
    };

    return $widget;
  }

  generateControllerAPI($widget, initialControllerAPI) {
    const controllerAPI = _.assign({}, initialControllerAPI);
    this.pushObservedObject(
      controllerAPI,
      $widget.props,
      _.keys($widget.props),
      this.onPropsChangedRegistrar
    );
    return controllerAPI;
  }
}

export default WidgetServices;

//***********************************************************
//                  END OF STATIC CODE
//***********************************************************
