import WidgetServices from './WidgetServices';
import App from '../App.svelte';

const widgetHandler = (config, $w, wixCodeApi) => {
  //***********************************************************
  //                  START OF STATIC CODE
  //***********************************************************
  const widgetProps = {};
  const widgetServices = new WidgetServices(widgetProps);
  const $widget = widgetServices.get$widget($w);
  //***********************************************************
  //                  END OF STATIC CODE
  //***********************************************************
  const initialControllerAPI = {};

  const onPropsChanged = (oldProps, newProps) => {
    //Handle changed props
  };

  $widget.onPropsChanged(onPropsChanged);

  return {
    pageReady: (...args) => {
      new App({
        target: {},
        props: {
          w: $w,
        },
      });
    },
    exports: () =>
      widgetServices.generateControllerAPI($widget, initialControllerAPI),
  };
};
export default widgetHandler;
