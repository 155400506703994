import widgetHandler from './widgetHandler';

export const initAppForPage = () => {};

export const createControllers = controllerConfigs =>
  controllerConfigs.map(({ $w, config, wixCodeApi }) => {
    if (config.type === 'vy80o') {
      return widgetHandler(config, $w, wixCodeApi);
    }

    return {
      pageReady: () => {},
      exports: {},
    };
  });
