<script>
  import uuid from 'uuid/v1';
  import {
    buttonActiveColor,
    buttonColor,
    FILTER_ALL,
    FILTER_ACTIVE,
    FILTER_COMPLETED,
  } from './constants';

  export let w;

  let todos = [];
  let currentFilter = FILTER_ALL;

  $: completedItemsCount = todos.filter(item => item.isCompleted).length;
  $: incompletedItemsCount = todos.filter(item => !item.isCompleted).length;
  $: isTodosExists = Boolean(todos.length);
  $: filteredTodos = todos.filter(item => {
    if (currentFilter === FILTER_ACTIVE) {
      return item.isCompleted === false;
    } else if (currentFilter === FILTER_COMPLETED) {
      return item.isCompleted;
    }
    return true;
  });

  function addTodo(value) {
    todos = todos.concat({
      _id: uuid(),
      value,
      isCompleted: false,
    });
  }

  function toggleTodo(id) {
    todos = todos.map(todo =>
      todo._id === id ? { ...todo, isCompleted: !todo.isCompleted } : todo
    );
  }

  function removeCompletedTodos() {
    todos = todos.filter(todo => todo.isCompleted === false);
  }

  // DOM interaction
  w('#root').show();
  w('#input').focus();

  w('#input').onKeyPress(({ key, target }) => {
    if (key === 'Enter' && target.value) {
      addTodo(target.value);
      target.value = '';
    }
  });
  w('#todoItems').onItemReady((item, itemData) => {
    item('#checkbox').onChange(() => toggleTodo(itemData._id));
    item('#checkbox').checked = itemData.isCompleted;
    item('#todoTitle').text = itemData.value;
  });
  w('#clear').onClick(removeCompletedTodos);
  w('#all').onClick(() => (currentFilter = FILTER_ALL));
  w('#active').onClick(() => (currentFilter = FILTER_ACTIVE));
  w('#completed').onClick(() => (currentFilter = FILTER_COMPLETED));

  // reactive DOM
  $: {
    w('#todoItems').data = filteredTodos;
  }

  $: {
    w('#all').style.color =
      currentFilter === FILTER_ALL ? buttonActiveColor : buttonColor;
    w('#active').style.color =
      currentFilter === FILTER_ACTIVE ? buttonActiveColor : buttonColor;
    w('#completed').style.color =
      currentFilter === FILTER_COMPLETED ? buttonActiveColor : buttonColor;
  }

  $: {
    w('#statusItems').text = `${incompletedItemsCount} items left`;
  }

  $: if (isTodosExists) {
    w('#status').show();
  } else {
    w('#status').hide();
  }

  $: if (completedItemsCount) {
    w('#clear').show();
  } else {
    w('#clear').hide();
  }
</script>
